import React, { Component } from 'react';
import './App.css';

function username(){
  return localStorage.getItem('s3c_user');
}

function getBaseline(){
  return {"cli": "asdfaserhidfadk", "nonce":"1ee80368adbc40148ec5eacb22374d5b"}
}

function getSession(){
  return 0;
}

function SignInButton(props) {
  return (
      <div id={props.id} onClick={props.click} class='loginBtn' >
          <span className={props.icon} > </span> 
          <span className="buttonText" > {props.text} </span> 
      </div>
  );
}

class SignUp extends Component {
  constructor(props) {
      super(props);
      this.state = {'isKnownUser': localStorage.getItem('userNames') !== null,
        'baseline': getBaseline(),
        'sessionId': getSession()
      };
      this.signIn = this.signIn.bind(this);      
  }
          
  signUp() {
      window.open("/index.html?action=signup")
  }

  signIn() {
    let x = this.state.baseline.cli;
    let y = this.state.baseline.nonce;
    let z = this.state.sessionId;
    window.location="https://login.s3curely.com/auth?scope=openid&client_id="+x+"&state="+z+"&nonce="+y+"&response_type=code&redirect_uri=https://my.s3curely.com/auth";
  }

render() {
      if (!this.state.isKnownUser) {
          return ( 
              <div className="signUps">
                  <div>No S3curely account?</div>
                  <SignInButton id="signup" icon="s3cicon" text='SignUp S3curely' click={this.signUp}/>
                  <div>Or, if you already have an account:</div>
                  <SignInButton id="signin" icon="s3cicon" text='SignIn S3curely' click={this.signIn}/>
              </div>
          );
      } else 
          return ('Sign in as '+username());
  }
}

class App extends Component {
  constructor(props){
    super(props)
  }
  
  render() {
    let s = window.location.search;
    if (s!==null && s.length>1){
      if (s.toLowerCase()=='?action=signup'){
        return(
          <div className = "App" >
            <header className="App-header" >
                <img src="badgeblack.png" className="App-logo" alt="logo" />
                <div id="auth-state">Sign up here:</div>
            </header>
            <main className="App-main" >
              <div><div>Email or Phone to verify:</div><input></input></div>
              <div><div>Desired Username:</div><input></input><div id="rg" style={{color:'#ff0000'}}>not available</div></div>
              <div><button>Verify</button></div>
            </main> 
          </div>
        );
      }
    }

    return ( 
      <div className = "App" >
        <header className="App-header" >
            <img src="badgeblack.png" className="App-logo" alt="logo" />
            <div id="auth-state">Welcome to My S3curely, Login or Signup to get started.</div>
        </header>
        <main className="App-main" >
          <SignUp/>
        </main> 
      </div>
    );
  }
}

export default App;